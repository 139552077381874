<template>
  <div  v-bind:style="{ background: '#f5f7fa'}" class="pt-15">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          class="pa-2  overflow-auto"
          :max-height="$vuetify.breakpoint.mdAndUp ? 700 : 300"
           outlined
          tile
        >
            <v-card-title
            class="text-uppercase 
            text-sm-caption text-md-h6
            font-weight-bold text-center white--text"
            style="background:#5167c2"
            >
            TELEPHONE DIRECTORIES
            </v-card-title> 

            <v-list three-line >
            <template v-for="(item, index) in this.DepartmentData">
            
            <v-divider
                :key="index"
                :inset="true"
            >
            </v-divider>

            <v-list-item
                :key="item.ID"
            >
                <v-list-item-content>
                    <v-row>
                        <!--
                        <v-col cols=1>
                            <v-list-item-avatar  
                                :size="$vuetify.breakpoint.mdAndUp ? 30 : 20"
                            >
                                <v-img :src="$api_url+item.Url"></v-img>
                            </v-list-item-avatar>
                        </v-col>
                        -->
                        <v-col
                            cols=12
                        >
                        <!-- class="text-md-h7 text-sm-h6 mb-1 font-weight-bold text-wrap"  -->
                            <v-list-item-title class="text-subtitle-2 font-weight-bold text-wrap " v-html="item.Name"></v-list-item-title>
                            <v-list-item-subtitle class="text-justify mb-3 text-body-2" v-html="`Contact Person: `+item.ContactPerson"></v-list-item-subtitle>
                            <v-list-item-subtitle  class="text-overline mb-4 d-flex flex-row-reverse  text-wrap text-body-2" v-html="item.Phone+`  Email:`+item.Email"></v-list-item-subtitle>
                        </v-col>
                    </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
            </v-list>
        </v-card>
      </v-col>
       <v-col
        cols="12"
        sm="4"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
            <v-card-title
            class="text-uppercase gray lighten-1
            text-sm-caption text-md-h6
            font-weight-bold text-center white--text"
            style="background:#5167c2"
            >
                HOW TO GET HERE
            </v-card-title>   
        </v-card>

      </v-col>
       <v-col
        cols="12"
        sm="4"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
            <v-card-title
            class="text-uppercase gray lighten-1
            text-sm-caption text-md-h6
            font-weight-bold text-center white--text"
            style="background:#5167c2"
            >
                <router-link to="/maps" style="text-decoration:none;color:white">      
                    MAPS
                </router-link> 
            </v-card-title> 
            <div ref="map-root"
                style="width: 100%; height: 80vh">
            </div>

        </v-card>
            
      </v-col>
    </v-row>
   </div>
</template>

<script>


import View from 'ol/View'
import Map from 'ol/Map'
//import coord from 'ol/coordinate';
import 'ol/ol.css'
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';


export default {
    name: 'ContactUs',
    components: {
        
    },
    data () {
        return {
            DepartmentData:[],
            markers: [],
            places: [],
            currentPlace: null,
          
        }
    },
    mounted(){
    
        this.getAllRecords(`/getAllRecords/departments/where ID > 0 /ID asc`,'departments');

            // create vector layer
        var source = new VectorSource();
        var vector = new VectorLayer({
            source: source
        });
        // create title layer
        var raster = new TileLayer({
            source: new OSM(),
        });
        // create map with 2 layer
        new Map({
            // the map will be created using the 'map-root' ref
           controls: defaultControls().extend([
            new ScaleLine({
                units: "degrees",
            }),
            ]), 
            target: this.$refs['map-root'],
           
            layers: [raster, vector],

            // the map view will initially show the whole world
            view: new View({
            projection: "EPSG:4326",  
            //projection: "EPSG:900913",  
            center: [  121.106080, 13.869289  ],
            //center: [  0, 0 ],
            maxZoom: 25,
            
            zoom: 17,
            constrainResolution: true
            }),
        })
      
      
    },
    
    methods:{
        getAllRecords:function(query,table){
        this.$axios.get( this.$api_url+query,{
            
            params: {
                
            }
        })
        .then(response => {
            //alert(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
                //alert(response.data);
                if(table=='departments'){
                    this.DepartmentData=response.data
                }
                
                
                //return response.data
            }
        })
        .catch(error => { console.log(error)})
        }, 
        
      
    }
}
</script>
<style lang="css">

</style>