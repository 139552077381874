<template>
  <div>
   
    <h2 class="pa-md-5 mx-lg-auto primary--text text-uppercase text-left pb-6" :class="$vuetify.breakpoint.mdAndDown ? 'title text-subtitle': 'title text-h4'">{{ title }}</h2>
    <!--<hr class="" width="100%" height="100px" SIZE="20" COLOR="blue">-->
    <!-- <v-divider thickness="100px" color="darkblue"></v-divider>  -->
   
    <div :style="!$vuetify.breakpoint.mdAndDown ? 'border:3px solid #5167c2' : 'border:3px solid #5167c2'"></div>
    <!-- <v-divider
      :thickness="300"
      class="border-opacity-25"
      color="info"
    ></v-divider> -->
    <br/><br/>
  </div>
</template>

<script>
export default {
  name : 'TitleComponent',
  props : ['title'],
  mounted() {
    console.log(this.$props);
  }
}
</script>

<style lang="css">


</style>