<template>
  <div v-if="test == true">
    <v-playback :url="url"></v-playback>
  </div>
</template>

<script>
export default {
  name : "VideoHome",
  props : ['test'],
  data(){
    return {
      url: require('../assets/sanjose_vid.mp4')
    }
  }
}
</script>

<style lang="scss">
  .videoPlayer {
    height: 600px;
    background-color: red;
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
</style>