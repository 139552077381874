<template>

  <v-parallax :height="viewHeight"
    dark
    :src="require('../assets/mayorsdescbg.png')"
  >

  <div class="mayors-content" ref="mcontent">
    
    <h2 class="pa-md-5 mx-lg-auto" :class="$vuetify.breakpoint.mdAndDown ? 'title h6': ''">FROM THE MAYOR'S DESK</h2>
    <!--<TitleComponent class="white--text text-left text-uppercase" title="FROM THE MAYOR'S DESK"/>-->
      <v-container :class="$vuetify.breakpoint.mdAndUp ? 'fluid': 'small-container'">
      <v-row justify="left" align="left">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '5' : '12'" md="5" sm="12" class="pa-0">
          <v-img :height="$vuetify.breakpoint.lgAndUp ? 550 : 200" :aspect-ratio="16/9" contain :src="require('../assets/mayor.png')" />
          <div class="text-md-h5 text-sm-h6 font-weight-bold text-center">Hon. Valentino "Ben" R. Patron</div>
       </v-col>
        <v-col  :cols="$vuetify.breakpoint.mdAndUp ? '4' : '11'" class="white--text pa-0">
         
          
          <div class="">&nbsp;</div>
          
          <div :class="$vuetify.breakpoint.mdAndUp ? 'text-md-h6 pb-3 pt-3' : 'text-sm-h6 pb-3 pt-3'">Positions</div>
          
          
            <ul :class="$vuetify.breakpoint.mdAndUp ? 'text-md-subtitle-1' : 'text-caption'">
            <li >Vice Mayor (2013-2016)</li>
            <li >Mayor (2016– present)</li>
            <li >LMP  Batangas  Chapter, President</li>
            <li >LMP -National , Executive Vice   President for Special Concerns</li>
            </ul>
          
          <div :class="$vuetify.breakpoint.mdAndUp ? 'text-md-h6 pb-3 pt-3' : 'text-sm-h6 pb-3 pt-3' ">Awards and Recognitions</div>
           
            <ul :class="$vuetify.breakpoint.mdAndUp ? 'text-md-subtitle-1' : 'text-caption'">
            <li >Most Innovative LGO Leader in TB Eliminations Program Implementation (March 22, 2019—Provincial Government Office)</li>
            <li >Plaque of Recognition: newly elected president of the League of Municipalities of the Philippines (LMP) of Batangas Province</li>
            <li >In recognition of support and assistance rendered to the people of Balete, Batangas  during the Taal Volcano Eruption. (August 3, 2020– Municipal of Balete)</li>
            <li >Leader in Action (March 1, 2021– Municipal Hall of San Jose)</li>
            <li >Support in Anti-Drug Campaign (December 28, 2016—San Jose Municipal Police Station)</li>
           </ul>
         
         

        </v-col>
       
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'"  md="3" sm="3" class="white--text pa-0 pt-6 pb-6">
        
          <!--
           <div class="text-md-h6 text-sm-h2">Welcome to the Municipality of San Jose Batangas Official Website!</div>
        -->
          <v-btn tile large color="blue lighten-2"
          @click="show = !show"
          >learn more about mayor ben
          </v-btn>

           <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <div  class="font-weight-bold">Name: Patron, Valentino Raz</div>
                <div>Birthday: May 25, 1956</div>
                <div>Address: Brgy. Pinagtung – ulan, San Jose Batangas</div>
                <div>Birth Place: -do-</div>
                <p>&nbsp;</p>
                <div class="font-weight-bold">Educational Background:</div>
                <div>Elementary: Pinagtung-ulan Elementary School (1963-1969)</div>
                <div>Secondary: Fernando Airbase High School (1969-1973)</div>
                <div>College: Feati University – BA Major in Accounting (1973-1977)</div>
                
                <p>&nbsp;</p>
                <div class="font-weight-bold">Work Experience:</div>
                <div>Municipal Mayor – 2016 – Present</div>
                <div>Municipal Vice Mayor – 2013 – 2016</div>
              </v-card-text>
            </div>
          </v-expand-transition>
          

         
        </v-col>
      </v-row>
     
    </v-container>
   
  </div>
  </v-parallax>
  
</template>

<script>
//import TitleComponent from '@/components/TitleComponent'
export default {
  name : "MayorsDesk",
  mounted() {
    this.viewHeight = this.$refs.mcontent.clientHeight;
  },
  data() {
    return {
      viewHeight : 0,
      show: false,
    }
  },
  components : {
    //TitleComponent
  }
}
</script>

<style lang="scss">
  .mayors-content {
    height: auto;
    bottom: 0;
    position: absolute;
    width: 100%;
    margin: 0 auto;
  };
 
</style>