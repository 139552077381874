<template>
  <div style="z-index:11">
  <!-- <v-container> -->

    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->

  <v-container >
    <TitleComponent class="primary--text text-uppercase text-left" :title="`Mayor's Desk Latest Updates`"/>
    <v-row class="mx-auto flex-child text-subtitle-2" >

      <!-- <v-col class="d-flex" cols="12"  > -->

        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify.breakpoint.smAndDown ? cols='4' : cols='12'" height="600" contain >
          <div class="mx-auto" :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:100%;'"  >
            <v-virtual-scroll
                :height="500"
                style="margin-top:13px;"
                :items="TableData2"
                item-height="75"

              >
                <template v-slot:default="{ item }">
                  <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                    <v-list-item-card >
                      <v-img :src="$api_url+item.Url" style="width:100px !important;height:95% !important"/>
                    </v-list-item-card>
                    <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;text-indent: 5px;"  >
                      <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>

                    </v-list-item-card>

                  </v-list-item>
                  <v-divider ></v-divider>
                </template>
            </v-virtual-scroll>
              <!-- <v-list  lines="two" v-for="(item,i) of this.TableData2" :key="item.ID"   :style="i==0 ? 'width:100%;cursor:pointer;height:auto' :'width:100%;cursor:pointer;height:auto;'">
                <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" >
                  <v-list-item-card >
                    <div style="width:55px">
                      <v-img :src="$api_url+item.Url" style="height:60px;width:60px;background-size:contain" ></v-img>
                    </div>

                  </v-list-item-card>
                  <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;text-indent:5px;"  >
                    <span style="" >{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>

                  </v-list-item-card>
                  <v-divider ></v-divider>
                </v-list-item>
              </v-list> -->
            </div>
        </v-col>
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify.breakpoint.smAndDown ? cols='4' : cols='12'" height="600" contain  >
          <div
              class="mx-auto"
              :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:600px;'"
            >
              <!-- <v-list  v-for="(item,i) of this.TableData" :key="item.ID"   :style="i==0 ? 'width:100%;cursor:pointer;height:auto;' :'width:100%;cursor:pointer;height:auto;margin-top:-13px'"  >
              <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" >
                <v-list-item-card>
                  <div style="width:55px">
                    <v-img :src="$api_url+item.Url" style="height:60px;width:60px;background-size:contain" ></v-img>
                  </div>
                </v-list-item-card>
                <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;text-indent:5px;"  >
                  <span style="" >{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>

                </v-list-item-card>
              </v-list-item>
              <v-divider ></v-divider>
              </v-list> -->
              <v-virtual-scroll
              :height="500"
              style="margin-top:13px;"
              :items="TableData"
              item-height="75"

            >
              <template v-slot:default="{ item }">
                <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                  <v-list-item-card >

                    <v-list-item-card >
                      <v-img :src="$api_url+item.Url" style="width:100px !important;height:95% !important"/>
                    </v-list-item-card>
                  </v-list-item-card>
                  <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;"  >
                    <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>

                  </v-list-item-card>

                </v-list-item>
                <v-divider ></v-divider>
              </template>
          </v-virtual-scroll>
            </div>
        </v-col>
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify.breakpoint.smAndDown ?  cols='4' : cols='12'">

          <div class="mx-auto text-center" @click="ClosePic"   :style="!$vuetify.breakpoint.smAndDown ? 'width:600px;' : 'width:350px;'" height="300">
            <v-card-subtitle class="text-center" >
              <v-img v-if="pic_source==''" :src="$api_url+TableData[0].Url" @click="ShowPic($api_url,TableData[0])" height="300" contain :width="!$vuetify.breakpoint.smAndDown ? 600 : 250"/>
              <v-img v-if="pic_source!=''" :src="pic_source"  max-height="300" contain max-width="600"/>
            </v-card-subtitle>


            <v-card-subtitle class="text-h5 text-center" v-if="pic_source==''"  >
            '' {{this.TableData[0].Name}} ''
            </v-card-subtitle>
            <v-card-subtitle style="text-indent:10px"  v-if="pic_source==''" >
              &nbsp;&nbsp;&nbsp;&nbsp;{{this.TableData[0].Content}}
            </v-card-subtitle>
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source!=''">
              '' {{title}} ''
              </v-card-subtitle>
              <v-card-subtitle style="text-indent:10px" v-if="pic_source!=''">
                &nbsp;&nbsp;&nbsp;&nbsp;{{content}}
              </v-card-subtitle>
          </div>
        </v-col>
        <!-- <v-col class="d-flex" v-if="!$vuetify.breakpoint.smAndDown ?  md='4' : md='12'"  max-height="600">
          <v-card
          class="mx-auto"
          style="width:800px"
        >
          <v-list lines="two" v-for="item of this.TableData"
          :key="item.ID"  style="width:100%;">
          <v-list-item>
            <v-list-item-card>
              <v-img :src="$api_url+item.Url" style="height:80px;width:80px;background-size:contain"></v-img>
            </v-list-item-card>
            <v-list-item-card class="text-subtitle-1 text-wrap" style="text-indent:10px">{{item.Name}}</v-list-item-card>
          </v-list-item>
            <v-divider inset></v-divider>
          </v-list>
        </v-card>
        </v-col> -->
      <!-- </v-col> -->
        <!-- <v-card class="mx-auto " max-width="400" max-height="600">
          <enlargeable-image :src="$api_url+this.TableData[0].Url" :src_large="$api_url+this.TableData[0].Url" />
          <v-card-subtitle class="text-caption text-lg-subtitle-2"  style="width:100%">
            {{this.TableData[0].Name}}
            <v-btn icon  @click="toggleshow(this.TableData[0])" >
              <v-icon>{{ this.TableData[0].Show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-expand-transition>
            <div v-show="this.TableData[0].Show">
                <v-divider></v-divider>
                <v-card class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                  <v-card-text class="pb-0">
                    <v-card-subtitle>
                      {{this.TableData[0].Content}}
                    </v-card-subtitle>
                  </v-card-text>
                </v-card>
            </div>
          </v-expand-transition>
        </v-card> -->

    </v-row>
    <!-- OLD  -->
    <!-- <v-card class="pa-md-5 mx-lg-auto" elevation="0" outlined rounded max-height="500">
      <swiper ref="mySwiper" :options="swiperOptions" >
        <swiper-slide v-for="(item, i) in this.TableData" :key="i" style="z-index:1" > -->
          <!-- <v-card class="mx-auto " max-width="350" max-height="600">
            <enlargeable-image :src="$api_url+item.Url" :src_large="$api_url+item.Url" />
            <v-card-subtitle class="text-caption text-lg-subtitle-2" >
                {{item.Name}}
              <v-btn icon  @click="toggleshow(item)" >
                <v-icon>{{ item.Show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-subtitle>
            <v-expand-transition>
              <div v-show="item.Show">
                  <v-divider></v-divider>
                  <v-card class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                    <v-card-text class="pb-0">
                      <v-card-subtitle>
                        {{item.Content}}
                      </v-card-subtitle>
                    </v-card-text>
                  </v-card>
              </div>
            </v-expand-transition>
          </v-card> -->
        <!-- </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </v-card> -->
  <!-- </v-container> -->

  <!-- <enlargeable-image :src="$api_url+this.TableData[0].Url" :src_large="$api_url+this.TableData[0].Url" /> -->

  <!-- <enlargeable-image :src_large="pic_source" /> -->
    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </v-container>
  </div>
</template>

<script>
//import ongoingprojectData from "@/json/ongoingprojects.json";
import {  directive } from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'

import axios from 'axios'
import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
// import style (>= Swiper 6.x)
//import 'swiper/swiper-bundle.css'

// import EnlargeableImage from '@diracleo/vue-enlargeable-image';



export default {
  name : 'MayorsDeskUpdates',
  components: {
    TitleComponent,
    Footer,
    // Swiper,
    // SwiperSlide,
    // EnlargeableImage

  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      title: "",
      content: "",
      pic_source: "",
      show: false,
      items:[],
      TableData:[],
      TableData2: [],
      swiperOptions: {
          slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 4 : 1,
          spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
            clickable: true
          }
          // Some Swiper option/callback...
        },
        LastID: "",
    }

    },

    mounted(){
      this.getAllPics(`/getAllRecords/mayordeskupdates/where ID > 0 /ID ASC `);
      this.swiper.slideTo(3, 1000, false)

    },

    updated(){


    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{
      loadPics(count){
        let res = Math.round(count / 2);

        this.TableData = [];
        this.TableData2 = [];
        this.getAllRecords2(`/getAllRecords/mayordeskupdates/where ID > 0 /ID ASC LIMIT  0,${res} `);

        this.getAllRecords(`/getAllRecords/mayordeskupdates/where ID > 0 /ID ASC LIMIT ${res},${res}`);

        // this.TableData=this.getAllRecords(`/getAllRecords/mayordeskupdates/where ID > 0 /ID desc LIMIT 0,6`);
        // this.TableData2=this.getAllRecords2(`/getAllRecords/mayordeskupdates/where ID > 0 /ID desc LIMIT 6,6`);
      },
      ClosePic(){

        this.pic_source='';
        this.title="";
        this.content="";
      },
      ShowPic(url,data)
      {
        this.pic_source = url+""+data.Url;
        this.title=data.Name;
        this.content=data.Content;
      },
      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value))
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) {

            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){

            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query){
       axios.get( this.$api_url+query,{
        params: {

          }
      })
      .then(response => {
          if (Object.keys(response.data).length > 0) {
              this.TableData=response.data;

            }
      })
      .catch(error => { console.log(error)})
    },
    getAllRecords2:function(query){
      this.TableData2 = [];
       axios.get( this.$api_url+query,{

        params: {

          }
      })
      .then(response => {
          if (Object.keys(response.data).length > 0) {
              this.TableData2=response.data;

            }
      })
      .catch(error => { console.log(error)})
    },
    getAllPics:function(query){
       axios.get( this.$api_url+query,{

        params: {

          }
      })
      .then(response => {
        if(response.data.length>0){
           this.loadPics(response.data.length)
       }
      })
      .catch(error => { console.log(error)})
    },
  }
}

</script>

<style scoped >

</style>