<template>
  <div>
    <TitleComponent class="text-center primary--text text-uppercase" title="GOVERNMENT AGENCY LINKS"/>
    <!-- <v-card
    elevation="0"
    outlined
    rounded
    :height="!$vuetify.breakpoint.smAndDown ? '230' : '700'"
    :max-height="!$vuetify.breakpoint.smAndDown ? '530' : '700'"
    
    >  -->
    <!-- {{ TableData }} -->
    <!-- {{ TableData }} -->
    <v-row class="mx-auto flex-child text-subtitle-2" style=";text-align:left">
      <v-col class="d-flex pa-0" style="border:none" :cols="!$vuetify.breakpoint.smAndDown ? '3' : '12'" v-for="(item, i) in this.TableData"  :key="i">
       <!-- <v-card class="mx-auto text-center" @click="ClosePic" style="width:99%;" > -->
        <div align="center" class="pb-0" style="margin-left:10px;text-align:left"  >
          <a :href="item.Website" style="text-decoration:none;color:#000" :title="item.Website"> {{ item.Name }}</a>
         <!-- <br/> -->
            <!-- <v-img
            :height="$vuetify.breakpoint.mdAndUp ? 50 : 50"
            :width="$vuetify.breakpoint.mdAndUp ? 50 : 50"
            :src="$api_url+item.Url"
            :lazy-src="item.Url"
            :alt="item.name"
            :title="item.name"
            aspect-ratio="1"
            class="grey lighten-2 align-center"
           
          > -->
          <!-- <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img> -->
          <!-- <div class="pa-0"></div> -->
         
          <!-- <v-divider/> -->
        </div>
           
        <!-- </v-card> -->
        
      </v-col>
     
    </v-row>
      <!-- <swiper ref="mySwiper" :options="swiperOptions" >
        <swiper-slide
          v-for="(item, i) in this.TableData"   
          :key="i"
        >
          <v-container>
            <v-card
            class="mx-auto"
            max-height="330"
            max-width="130"
            hover
            raised
            >   
              <button v-on:click="loadsite(item.Website)"> 
                1
                <v-img
                 
                  :height="$vuetify.breakpoint.mdAndUp ? 130 : 80"
                  :width="$vuetify.breakpoint.mdAndUp ? 130 : 80"
                  :src="$api_url+item.Url"
                  :lazy-src="item.Url"
                  :alt="item.name"
                  :title="item.name"
                  aspect-ratio="1"
                  class="grey lighten-2 align-center"
                >
            
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </button>
            </v-card>
          </v-container> 
        </swiper-slide> 
        <div class="swiper-pagination" slot="pagination"></div> 
      </swiper> -->
    <!-- </v-card>   -->
     <!-- <hr width="100%" height="80px" SIZE="20" COLOR="yellow"> -->
  </div>
  
</template>

<script>

import {  directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent'

export default {
  name : 'AgencyLinks',
  components : {
    TitleComponent,
    // Swiper,
    // SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
    TableData:[],  
    swiperOptions: {
      slidesPerView: (this.$vuetify.breakpoint.mdAndUp ? 11 : 4),
      centeredSlides: true,
      spaceBetween: 2,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
      // Some Swiper option/callback...
      //(this.$vuetify.breakpoint.lgAndUp ? 4 : 4),
    },
   
    }
  },
  mounted(){
  
    this.getAllRecords();
    this.swiper.slideTo(3, 1000, false); 
    
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  

  methods : {
    loadsite: function(site) {
      //alert();
      window.location.href = site;
    },
    getAllRecords:function(){
      //alert(this.BranchCode);       
      axios.get( this.$api_url+`/getAllRecords/govlinks/where ID > 0/ ID asc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.TableData = response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },  

  }
}
</script>

<style lang="scss" scoped>
  
</style>