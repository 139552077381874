<template>
  <div style="background: '#f5f7fa';z-index:1;width:100%;" >
    <v-container fluid>
        <!--<Announcement/>  v-bind:style="{ background: '#f5f7fa',z-index:'1'}" -->
    <!-- <VideoHome/> -->
      <Carousels />
      <!-- <CovidBar /> -->
        <MayorsDesk class="spacing-playground pt-10"/>
        <SBMembers :targetPage="this.$vnode.tag" class="spacing-playground pt-10"/>
        <MayorsDeskUpdates class="spacing-playground pt-10"/>
        <MayorVideos class="spacing-playground pt-10"/>
        <br/><br/><br/>
        <v-container class="justify-center pt-15">
          <v-row class="mx-auto flex-child justify-center">
            <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-6 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'" >
              <v-card style="border:none;box-shadow:none">
                <div style="background:#5167c2;color:white;height:200px;width:300px;border-radius:10px;text-align:center" >
                  <p class="text-h6 pt-10"> BIDDINGS</p>
                  <div>
                    <v-btn to="/Biddings">Learn More</v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-6 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'" >
              <v-card style="border:none;box-shadow:none">
                <div style="background:#29B6F6;color:white;height:200px;width:300px;border-radius:10px;text-align:center" >
                  <p class="text-h6 pt-10">LIST OF ONGOING<br/>PROJECTS</p>
                  <div>
                    <v-btn to="/ongoingprojects">Learn More</v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-6 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'" >
              <v-card style="border:none;box-shadow:none">
                <div style="background:#5167c2;color:white;height:200px;width:300px;border-radius:10px;text-align:center" >
                  <p class="text-h6 pt-10"> LIST OF COMPLETED PROJECTS</p>
                  <div>
                    <v-btn to="/completedprojects">Learn More</v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
            
          </v-row>
         
          
        </v-container>
        <br/><br/><br/>
        <!-- <Programs class="spacing-playground pt-10"/>
        <OngoingProjects class="spacing-playground pt-10"/>
        <CompletedProjects class="spacing-playground pt-10"/> -->
        
        <!-- <CovidUpdates class="spacing-playground pt-10"/> -->
        <!--<Calendar/>-->
        <MunicipalActivities class="spacing-playground pt-10"/>
        
        <!--<Services/>-->
        <ContactUs class="spacing-playground pt-10"/>
        <br/>
       
        
      
     
    </v-container>
       
        <AgencyLinks class="spacing-playground pt-10"/>
        <!--<BottomNav/>-->
        <Footer class="spacing-playground pt-10"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import Announcement from '@/components/Announcement'
//import VideoHome from '@/components/VideoHome'
//import NewsFeeds from '@/components/NewsFeeds'
// import Programs from '@/components/Programs'
import AgencyLinks from '@/components/AgencyLinks'
import MayorsDesk from '@/components/MayorsDesk'
import Footer from '@/components/Footer'
//import BottomNav from '@/components/BottomNav'
//import Services from '@/components/Services'
// import OngoingProjects from '@/views/OngoingProjects'
// import CompletedProjects from '@/components/CompletedProjects'
// import CovidUpdates from '@/components/CovidUpdates'
import Carousels from '@/components/Carousels'
import MayorsDeskUpdates from '@/components/MayorsDeskUpdates'
import MayorVideos from '@/components/MayorVideos'
import SBMembers from '@/components/SBMembers'
//import Calendar from '@/components/Calendar'
import MunicipalActivities from '@/components/MunicipalActivities'
import ContactUs from '@/components/ContactUs'

export default {
  name: 'Home',
  components: {
    Carousels,
    //NewsFeeds,
    // Programs,
    MayorsDesk,
    MayorVideos,
    //Services,
    AgencyLinks,
    Footer,
    //BottomNav,
    //VideoHome,
    // OngoingProjects,
    // CompletedProjects,
    //VaccineUpdates,
    // CovidUpdates,
    MayorsDeskUpdates,
    //Announcement,
    SBMembers,
    //Calendar,
    MunicipalActivities,
    ContactUs
  },
  
}
</script>
<style lang="scss">
$material-light: (
  'background': rgb(82, 78, 78)
);
</style>