<template>
  <div class="primary lighten-1 top-header">
    <v-container>
      <v-row class="d-flex justify-space-between" no-gutters>

        <div class="white--text">
         <Clock/>
        </div>

        <div class="d-flex justify-end">
          
          <div class="mr-1 ml-2">
            <div class="white--text">Follow us:</div>
          </div>
          <div class="pr-1">
            <v-btn
              icon
              color="white"
              x-small
              href="https://www.facebook.com/LGUSanJoseBatangas"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </div>
          <div class="pr-1">
            <v-btn
              icon
              color="white"
              x-small
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
          </div>
          <div class="pr-1">
            <v-btn
              icon
              color="white"
              x-small
            >
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </div>
          <div class="pr-1">
            <div class="d-flex justify-end">
              <div class="pr-1 text-uppercase  white--text">
                Trunk Line : 7798550    
              </div>
          </div>
          </div>
        </div>
            
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
//Emergency No.s: (POLICE) 7262205; 7021962  / (MDRRMO) 7860816  / (FIRE) 7263630; 0966-746-2193; 0929-293-0106
import Clock from '@/components/Clock';
export default {
  name : 'TopHeader',
  components : {
    Clock
  },
  data () {
    return {
      'emergency_numbers' : 'NDRMC 984-5871'
    }
  }
}
</script>

<style lang="scss">
  .top-header {
    font-size: 10pt;
  }
</style>