<template>
  <div >
    <v-container>
      <!-- {{ TableData }}   -->
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
    <TitleComponent :title="'Mayor Videos'"/>
    <v-row class="mx-auto flex-child text-subtitle-2" >

      <!-- <v-col class="d-flex" cols="12"  > -->
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'" max-height="600" >

          <div
              class="mx-auto"
              :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:600px;'"
            >
              <v-virtual-scroll
                :height="400"
                style="margin-top:13px;"
                :items="TableData2"
                item-height="70"

              >
                <template v-slot:default="{ item }">
                  <v-list-item @click="ShowPic(item.Link,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                    <v-list-item-card >

                      <iframe
                        :src="item.Link"
                          :height="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                          :width="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                          scrolling="no" frameborder="0"
                          allowTransparency="true"
                          allowFullScreen="true"
                      >
                      </iframe>
                    </v-list-item-card>
                    <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;"  >
                      <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-motion-play-outline</v-icon></span>

                    </v-list-item-card>

                  </v-list-item>
                  <v-divider ></v-divider>
                </template>
            </v-virtual-scroll>

              <!-- <v-list lines="two" v-for="(item,a) of this.TableData2" :key="item.ID"  style="width:100%;cursor:pointer;" >
              <v-list-item @click="ShowPic(item.Link,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                <v-list-item-card >

                  <iframe
                     :src="item.Link"
                      :height="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                      :width="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                      scrolling="no" frameborder="0"
                      allowTransparency="true"
                      allowFullScreen="true"
                  >
                  </iframe>
                </v-list-item-card>
                <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;"  >
                  <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-motion-play-outline</v-icon></span>

                </v-list-item-card>
                <v-divider ></v-divider>
              </v-list-item>

              </v-list> -->
            </div>
        </v-col>
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex justify-center mx-auto' : 'd-flex  justify-center mx-auto'" :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'" max-height="600" >
          <div
              class="mx-auto"
              :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:600px;'"
            >
            <v-virtual-scroll
              :height="400"
              :items="TableData"
              item-height="70"
            >
              <template v-slot:default="{ item }">
                <v-list-item @click="ShowPic(item.Link,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                  <v-list-item-card >

                    <iframe
                      :src="item.Link"
                        :height="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                        :width="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                        scrolling="no" frameborder="0"
                        allowTransparency="true"
                        allowFullScreen="true"
                    >
                    </iframe>
                  </v-list-item-card>
                  <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;"  >
                    <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-motion-play-outline</v-icon></span>

                  </v-list-item-card>

                </v-list-item>
                <v-divider ></v-divider>
              </template>
          </v-virtual-scroll>
              <!-- <v-list  v-for="(item,i) of this.TableData"
              :key="item.ID"  style="width:100%;cursor:pointer;" >
              <v-list-item @click="ShowPic(item.Link,item)" title="Click To View Contents" :style="i==0 ? '' : 'margin-top:-13px'">
                <v-list-item-card>
                  <iframe
                     :src="item.Link"
                      :height="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                      :width="$vuetify.breakpoint.mdAndUp ? 60 : 60"
                      scrolling="no" frameborder="0"
                      allowTransparency="true"
                      allowFullScreen="true"
                  >
                  </iframe>
                </v-list-item-card>
                <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;"  >
                  <span style="text-indent:10px;" >{{item.Name}}<v-icon style="float:right">mdi-motion-play-outline</v-icon></span>

                </v-list-item-card>

              </v-list-item>
              <v-divider ></v-divider>
              </v-list> -->
            </div>
        </v-col>
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-0 justify-center mx-auto'" :cols="!$vuetify.breakpoint.smAndDown ? '4' : '12'">

          <div class="mx-auto text-center" @click="ClosePic" :style="!$vuetify.breakpoint.smAndDown ? 'width:600px;' : 'width:350px;'" height="300">
            <v-card-subtitle class="text-center">
              <!--   src="https://www.youtube.com/embed/1HG0W3qmN6I?mute=1&autoplay=1&origin=https://youtu.be/1HG0W3qmN6I" -->
              <!-- {{ pic_source }} -->
              <iframe
               :src="pic_source"
                :height="$vuetify.breakpoint.mdAndUp ? 250 : 250"
                :width="$vuetify.breakpoint.mdAndUp ? 500 : 250"
                scrolling="no" frameborder="0"
                allowTransparency="true"
                allowFullScreen="true"
            >
            </iframe>
            </v-card-subtitle>


            <v-card-subtitle class="text-h5 text-center" v-if="pic_source==''"  >
            '' {{this.TableData[0].Name}} ''
            </v-card-subtitle>
            <v-card-subtitle style="text-indent:10px"  v-if="pic_source==''" >
              &nbsp;&nbsp;&nbsp;&nbsp;{{this.TableData[0].Content}}
            </v-card-subtitle>
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source!=''">
              '' {{title}} ''
              </v-card-subtitle>
              <v-card-subtitle style="text-indent:10px" v-if="pic_source!=''">
                &nbsp;&nbsp;&nbsp;&nbsp;{{content}}
              </v-card-subtitle>
          </div>
        </v-col>
    </v-row>
  <!-- <swiper ref="mySwiper" :options="swiperOptions" navigation class="spacing-playground pt-6">
    <swiper-slide
      v-for="(item, i) in this.TableData"
      :key="i"
    >

      <v-card
        class="pa-md-5 mx-lg-auto"
        :max-width="$vuetify.breakpoint.mdAndUp ? 350 : 300"
        max-height="650"

      > -->
    <!--
    <iframe id="ytplayer" type="text/html"
        :height="$vuetify.breakpoint.lgAndUp ? 250 : 200"
        :width="$vuetify.breakpoint.lgAndUp ? 350 : 150"
        :src="item.FBUrl"
        frameborder="0">
    </iframe>
    -->

    <!-- <iframe
        :src="`https://www.facebook.com/plugins/video.php?href=${item.Url}&show_text=0`"
        :height="$vuetify.breakpoint.mdAndUp ? 250 : 250"
        :width="$vuetify.breakpoint.mdAndUp ? 350 : 300"
        scrolling="no" frameborder="0"
        allowTransparency="true"
        allowFullScreen="true"
    >
    </iframe> -->

    <!--
     <div class="fb-video"
     :data-href="item.Url"
     :data-width="$vuetify.breakpoint.lgAndUp ? 350 : 150"
     :height="$vuetify.breakpoint.lgAndUp ? 500 : 200"
     data-show-text="false">
    <div class="fb-xfbml-parse-ignore">

    </div>
    </div>
    -->
    <!-- </v-card>

    </swiper-slide>

    <div class="swiper-pagination" slot="pagination"></div>
  </swiper> -->



    <!--</v-container>-->

    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
    </v-container>
  </div>
</template>

<!--<script async defer :src="`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2`">
-->
<script>

//import ongoingprojectData from "@/json/ongoingprojects.json";
import {  directive } from 'vue-awesome-swiper'

import axios from 'axios'
import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
// import style (>= Swiper 6.x)
//import 'swiper/swiper-bundle.css'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

export default {
  name : 'MayorsVideo',
  components: {
    TitleComponent,
    Footer,
    // Swiper,
    // SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      show: false,
      items:[],
      TableData:[],
      TableData2:[],
      title: "",
      content: "",
      pic_source: "",
      swiperOptions: {
          slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 4 : 1,
          slidesPerColumn: this.$vuetify.breakpoint.mdAndUp ? 1: 1,
          spaceBetween: 10,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
          // Some Swiper option/callback...
        },
        CountTable: [],
        dataCount:0,
    }

    },

    mounted(){
      //alert(this.items);
      this.getAllRecordsCount(`/getAllRecords/mayorvideos/where ID > 0 /ID DESC `);

      // this.TableData=this.getAllRecords(`/getAllRecords/mayorvideos/where ID > 0 /ID DESC LIMIT 0,5`);

      //alert(THIS.TableData);
      this.swiper.slideTo(3, 1000, false)

    },

    updated(){


    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{
       getMayorsVideos(count){
        let res = Math.round(count / 2);

        this.TableData = [];
        this.TableData  = this.getAllRecords(`/getAllRecords/mayorvideos/where ID > 0 /ID DESC LIMIT ${res},${res}`);
        this.TableData2=this.getAllRecords2(`/getAllRecords/mayorvideos/where ID > 0 /ID DESC LIMIT  0,${res} `);
      },
      ClosePic(){

        this.pic_source='';
        this.title="";
        this.content="";
      },
      ShowPic(url,data)
      {

        this.pic_source = url;
        this.title=data.Name;
        this.content=data.Content;
      },

      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value))
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) {

            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){

            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{

        params: {

          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              this.TableData=[];
              let i =0;
              for(const arr of response.data){

                let checkFB = arr.Url.includes('//fb')
                let checkFB2 = arr.Url.includes('facebook')
                let checkYT = arr.Url.includes('youtube')
                let link = ""
                if(checkFB==true || checkFB2 ==true){
                  link = `https://www.facebook.com/plugins/video.php?href=${arr.Url}&show_text=0`
                }else{
                  if(checkYT==true){
                    let iVideod = "";
                    let uri = arr.Url.split('?');
                    if(uri.length == 2) {
                      let vars = uri[1].split('&');
                      let getVars = {};
                      let tmp = '';
                      vars.forEach(function(v) {
                        tmp = v.split('=');
                        if(tmp.length == 2)
                          getVars[tmp[0]] = tmp[1];
                      });
                      iVideod =getVars
                    }
                    link = "https://www.youtube.com/embed/"+iVideod.v+"?mute=0&autoplay=1&origin="+arr.Url
                  }
                }
                if(i==0){
                  this.pic_source  = link
                }
                this.TableData[i]= Object.assign({},this.TableData[i],{
                      ...arr,
                      Link:link
                  });
                  i++;
              }

              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },
    getAllRecords2:function(query){
      this.TableData2=[];
      axios.get( this.$api_url+query,{

        params: {

          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
            this.TableData2 = [];
            let i =0;
              for(const arr of response.data){

                let checkFB = arr.Url.includes('//fb')
                let checkFB2 = arr.Url.includes('facebook')
                let checkYT = arr.Url.includes('youtube')
                let link = ""
                if(checkFB==true || checkFB2 ==true){
                  link = `https://www.facebook.com/plugins/video.php?href=${arr.Url}&show_text=0`
                }else{
                  if(checkYT==true){
                    let iVideod = "";
                    let uri = arr.Url.split('?');
                    if(uri.length == 2) {
                      let vars = uri[1].split('&');
                      let getVars = {};
                      let tmp = '';
                      vars.forEach(function(v) {
                        tmp = v.split('=');
                        if(tmp.length == 2)
                          getVars[tmp[0]] = tmp[1];
                      });
                      iVideod =getVars
                    }
                    link = "https://www.youtube.com/embed/"+iVideod.v+"?mute=0&autoplay=1&origin="+arr.Url
                  }
                }
                if(i==0){
                  this.pic_source  = link
                }
                this.TableData2[i]= Object.assign({},this.TableData2[i],{
                      ...arr,
                      Link:link
                  });
                  i++;
              }
            }
      })
      .catch(error => { console.log(error)})
    },
    getAllRecordsCount:function(query){
      this.CountTable = [];
      axios.get( this.$api_url+query,{

        params: {

          }
      })
      .then(response => {

       if(response.data.length>0){
           this.CountTable =  response.data;
           this.getMayorsVideos(this.CountTable.length)
       }

      })
      .catch(error => { console.log(error)})


    },
  }
}

</script>

<style lang="css">


</style>