import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this , location).catch(err => err)
}

  const routes = [
 
  {
    path: '/',
    name: 'Home',
    meta: { title: "San Jose Batangas Official Web Page" },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path : '/sbmembers',
    name : 'SBMembers',
    component: () => import('../components/SBMembers.vue')
  },
  {
    path : '/covidupdates',
    name : 'CovidUpdates',
    component: () => import('../components/CovidUpdates.vue')
  },
  {
    path : '/departments/:departmentName',
    name : 'Departments',
    component: () => import('../views/Departments.vue')
  },
  {
    path : '/barangays',
    name : 'Barangays',
    component: () => import('../views/Barangays.vue')
  },
  {
    path : '/tourism',
    name : 'Tourism',
    component: () => import('../views/Tourism.vue')
  },
  {
    path : '/calendar',
    name : 'Calendar',
    component: () => import('../components/Calendar.vue')
  },
  {
    path : '/completedprojects',
    name : 'CompletedProjects',
    component: () => import('../components/CompletedProjects.vue')
  },
  {
    path : '/ongoingprojects',
    name : 'OngoingProjects',
    component: () => import('../views/OngoingProjects.vue')
  },
  {
    path : '/newsfeeds',
    name : 'NewsFeeds',
    component: () => import('../components/NewsFeeds.vue')
  },
  {
    path : '/news',
    name : 'News',
    component: () => import('../views/News.vue')
  },
  {
    path : '/gallery',
    name : 'Gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path : '/downloadables',
    name : 'Downloadables',
    props: {link:'ALL'},
    component: () => import('../views/Downloadables.vue')
  },
  {
    path : '/services',
    name : 'Services',
    props: {link:'ALL'},
    component: () => import('../views/Services.vue')
  },
  {
    path : '/biddings',
    name : 'Biddings',
    component: () => import('../views/Biddings.vue')
  },
  {
    path : '/maps',
    name : 'Maps',
    component: () => import('../views/Maps.vue')
  },
  {
    path : '/viewcontent/:ID/:Table',
    name : 'ViewContent',
    component: () => import('../views/ViewContent.vue')
  },
  {
    path : '/fulldisclosure',
    name : 'FullDisclosure',
    props: {link:'ALL'},
    component: () => import('../views/FullDisclosure.vue')
  },
  { 
    path: "*", 
    component: () => import('../views/Pagenotfound.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
