<template>
  <div >
       
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">
    ref="mySwiper" :options="swiperOptions"  
      -->
  <v-container >    
  <TitleComponent class="primary--text text-uppercase text-left" :title="'Sangguniang Bayan'"/>
    <!-- {{ TableData }} -->
    <div class="d-flex mx-auto justify-center pb-8">
      <v-row class="mx-auto flex-child text-subtitle-2" >
        <v-col class="mx-auto " cols="12">
          <div style="width:200px;height:200px;margin:auto;" class="text-center ">
              <div>
                <v-img :src="$api_url+TableData[0].Url" height="140"  contain></v-img>
              </div>
              <div class="text-wrap text-center  pt-1" style="width:180px;">
                
                {{ TableData[0].Name }}
              </div>
             <div style="background:#5167c2;color:white;letter-spacing:1px">
              <v-divider></v-divider>
              <span style="font-weight:bold;">{{ TableData[0].Position }}</span>
             </div>
              
          </div>
        </v-col>
        <v-col class="justify-center" :cols="!$vuetify.breakpoint.smAndDown ? '3' : '5'" v-for="(item,i) of this.SBMembers" :key="i">
          <div style="width:180px;height:150px;margin:auto;" :class="!$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-center text-caption'">
            <v-img :src="$api_url+item.Url" style="height:120px;width:200px;" size="sm" contain></v-img>
            
            <div class="text-wrap text-center  pt-1" style="width:180px;">
              
              {{ item.Name }}
            </div>
            <div style="background:#5167c2;color:white;letter-spacing:1px">
               <span style="font-weight:bold;">
              <v-divider></v-divider>
              {{ item.Position }}
            </span>
            </div>
           
        </div>
        </v-col>
      </v-row>
      </div>
      <div >
        <v-row class="text-subtitle-2  justify-center pa-2" cols="12">
          <v-col  :cols="!$vuetify.breakpoint.smAndDown ? '3' : '12'" v-for="(item,i) of this.SBMembers2" :key="i" style="">
            <div style="width:200px;height:180px;margin:auto" :class="!$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-center text-caption'">
              <v-img :src="$api_url+item.Url" style="height:120px;width:200px;" size="sm" contain></v-img>
              
              <div class="text-wrap text-center  pt-1" style="width:205px;">
                
                {{ item.Name }}
              </div>
              <div style="background:#5167c2;color:white;letter-spacing:1px">
                <span style="font-weight:bold;">
                <v-divider></v-divider>
                {{ item.Position }}
              </span>
              </div>
              
          </div>
          </v-col>
          
        </v-row>
        
    </div>

  <!-- <v-card
      class="pa-md-8 mx-lg-auto "
      elevation="0"
      outlined
      rounded
      :height="this.$route.name=='Home' && !$vuetify.breakpoint.smAndUp  ? 420 : 420"
    
      style="z-index:999;"
      > 
  <swiper  ref="mySwiper" class="swiper " :options="swiperOptions" navigation>
   
    <swiper-slide v-for="(item, i) in this.TableData" :key="i" >  
      
      <v-card
        class="mx-auto justify-center yellow lighten-4"
        height="380"
        :width="!$vuetify.breakpoint.smAndDown ? '500' : '450'"
        hover
        raised
      >
      <enlargeable-image :src="$api_url+item.Url" @click="ThisEnLarge(`${$api_url+item.Url}`)" />
      
      <v-card-subtitle
        class="primary--text text-uppercase text-center yellow lighten-4 
        text-caption-4 text-lg-caption-4"
      >
      <div>{{item.Name}}</div>
      <div>{{item.Position}}</div>
      </v-card-subtitle>
     
      
    </v-card>
  
    </swiper-slide>
  </swiper>
  </v-card> -->
  <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </v-container>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide,directive } from 'vue-awesome-swiper'
import { directive } from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

// swiper bundle styles
//import 'swiper/swiper-bundle.min.css'

// Import Swiper Vue.js components
//import { Swiper, SwiperSlide } from 'swiper/vue';
 // Import Swiper styles
 
// import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'

export default {
  name : 'SBMembers',
  components: {
    TitleComponent,
    Footer,
    // Swiper,
    // SwiperSlide,
    // EnlargeableImage
},
directives: {
    swiper: directive
  },  
 
  data () {
    return {
      show: false,
      items:[],
      TableData:[],
      SBMembers: [],
      SBMembers2: [],
      targetPage:this.$route.params.targetPage,
      swiperOptions: {
          slidesPerView: (this.$route.name=='Home' ? 
          !this.$vuetify.breakpoint.smAndDown ? 11 : 2 : 6),
          spaceBetween: 10,
          slidesPerColumn: (this.$route.name=='Home' ? 1 : 2),
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
          // make 1 sb members per slide in smartphones
          //(this.$vuetify.breakpoint.mdAndUp ? 11 : 1),
        }
    }

    },

    mounted(){
     // alert(this.$route.name);
      //this.swiperOptions.slidesPerView=(this.$vuetify.breakpoint.lgAndUp ? 11 : 4)
      this.TableData=this.getAllRecords(`/getAllRecords/sbmembers/where ID > 0 /ID asc`);
      //alert(THIS.TableData);
     this.swiper.slideTo(3, 1000, false); 
    //alert(this.swiperOptions.slidesPerView)

    },  

    updated(){

     
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      },
     
    },

    methods:{

      toggleshow(item){
        item.Show=!item.Show
      },
      
      
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              let a = response.data;

              this.TableData=a.filter((search)=>{
                return (search.Rank==1)
              });
              this.SBMembers=a.filter((search)=>{
                return (search.Rank>1 && search.Rank<=9)
              });
              this.SBMembers2=a.filter((search)=>{
                return (search.Rank>9)
              });
              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss">
 @import 'swiper/swiper.scss';
   .swiper {
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    border:none;

    .swiper-slide {
      height: 400px;
      border:none;
    }
  };


</style>