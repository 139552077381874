<template>
    <v-app>
      <Navbar/>
      <div v-if="$vuetify.breakpoint.smAndDown"><br/><br/></div>
      <div v-if="!$vuetify.breakpoint.smAndDown"><br/><br/><br/><br/></div>
    <v-main >
        <router-view ></router-view>
    </v-main>
        
  </v-app>
  
</template>

<script>
import Navbar from '@/components/Navbar';
export default {
  name: 'App',
  components: {
    Navbar
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Pridi&display=swap');
  $body-font-family: 'Open Sans', sans-serif;
  $title-font: 'Pridi', serif;
  $heading-font: 'Pridi', serif;
 

  .v-application {
   font-family: $body-font-family, sans-serif !important;
    h1, h2, h3, h4, h5, h6, .title, .headline { // To pin point specific classes of some components
       font-family: $title-font, sans-serif !important;
    }
    button {
      font-family : $title-font
    }
   
 }

 .small-container {
    max-width: 1200px !important;
  }

  .debug {
    border: 1px dotted red;
  }
</style>
