<template>
  
  <div class="mt-10 secondary white--text">
   
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': 'fluid'">
     
      <v-row>
        <v-col cols="12" md="6">LGU San Jose Batangas <br> Views : <span id="Visits" style="color:yellow"></span></v-col>
        <v-col  class="text-right" cols="12" md="6">Privacy Policy</v-col>
      </v-row>
    </v-container>
  
  </div>
</template>

<script>
export default {
  name : 'Footer',
  props: {
    Visits: Number,
  },
  mounted(){
    // countapi.get('localhost', '9b066e31-7e0d-42a6-8cff-e3da062883be').then((result) => { 
    //   console.log(result);
    //   this.Visits = result.value;

    //  });
    this.CountViews();
  },
  methods: {
    CountViews(){
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.countapi.xyz/hit/sanjosebatangas.gov.ph");
      xhr.responseType = "json";
      xhr.onload = function() {
          document.getElementById('Visits').innerText = this.response.value;
      }
      xhr.send();
    },
  },
}
</script>

<style lang="scss">
  
</style>