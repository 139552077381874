<template>
  <div >
       
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">
    <TitleComponent class="primary--text text-uppercase text-center" :title="'Municipal Activities'"/>
  -->
  <v-container>
    <TitleComponent :title="'News'"/>
    <v-row class="mx-auto flex-child text-subtitle-2 justify-between">
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '5' : '12'" max-height="600">
          <div
              class="mx-auto"
              :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:600px;'"
            >
              <v-list lines="two" v-for="(item,a) of this.News" :key="item.ID"  style="width:100%;cursor:pointer;" >
              <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                <v-list-item-card >
                  <v-img @mouseover="item.Show = true"
                          @mouseleave="item.Show = false"
                          :height="$vuetify.breakpoint.lgAndUp ? 60 : 60"
                          :width="$vuetify.breakpoint.lgAndUp ? 60 : 60"
                          :aspect-ratio="16/9"
                          :src="$api_url+item.Url"
                          :lazy-src="$api_url+item.Url"
                        
                          :alt="item.Name"
                          :title="item.Name"> </v-img>
                  
                </v-list-item-card>
                <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;text-indent:20px;"  @click="ShowEnlarge(item)">
                  <span>{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>
                  <span class="text-caption" v-html="formatData(item.CreatedDate)" style="float:right"></span>
                 
                </v-list-item-card>
              
              </v-list-item>
              <v-divider ></v-divider>
              </v-list>
            </div>
        </v-col>
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-end mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify. breakpoint.smAndDown ?  cols='5' : cols='12'">
          <div class="mx-auto text-center justify-center"    :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:350px'" align="center">
            <v-card-subtitle class="text-center" align="center" style="width:100%;">
              <div align="center">
                <v-img v-if="pic_source==''" :src="$api_url+News[0].Url" @click="ShowPic($api_url,News[0])" height="300" contain :width="!$vuetify.breakpoint.smAndDown ? 600 : 250" align="center"/>
                <v-img v-if="pic_source!=''" :src="$api_url+pic_source"  max-height="300" contain max-width="600" align="center"/>
              </div>
             
            </v-card-subtitle>
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source==''"  >
            '' {{this.News[0].Name}} ''
            </v-card-subtitle>
            <v-card-subtitle style="text-indent:10px"  v-if="pic_source==''" > 
              &nbsp;&nbsp;&nbsp;&nbsp;{{this.News[0].Content}}
            </v-card-subtitle>
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source!=''">
              '' {{SelectedName}} ''
              </v-card-subtitle>
              <v-card-subtitle style="text-indent:10px" v-if="pic_source!=''"> 
                &nbsp;&nbsp;&nbsp;&nbsp;{{SelectedContent}}
              </v-card-subtitle>
          </div>
        </v-col>
      </v-row>
      <br/>
      
      </v-container>
      <v-container>
      <TitleComponent :title="'EVENTS'" />
      <v-row class="mx-auto flex-child text-subtitle-2 justify-between" cols="12" style="width:100%">
        <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'"  :cols="!$vuetify.breakpoint.smAndDown ? '5' : '12'" max-height="600">
          <div
              class="mx-auto"
              :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:600px;'"
             
            >
          
            <!-- {{ EventData }} -->
              <v-list lines="two" v-for="(item,a) of this.EventData" :key="item.ID"  style="width:100%;cursor:pointer;" >
              <v-list-item @click="ShowPic($api_url,item)" title="Click To View Contents" :style="a==0 ? '' : 'margin-top:-13px'" >
                <v-list-item-card >
                  <!--  :src="`https://www.facebook.com/plugins/video.php?href=${TableData[0].Url}&show_text=0`" 
                     src="https://www.facebook.com/plugins/video.php?href=https://fb.watch/iXUGWLK_QN/&show_text=0"
                  -->
                  <v-img @mouseover="item.Show = true"
                          @mouseleave="item.Show = false"
                          :height="$vuetify.breakpoint.lgAndUp ? 60 : 60"
                          :width="$vuetify.breakpoint.lgAndUp ? 60 : 60"
                          :aspect-ratio="16/9"
                          :src="$api_url+item.Url"
                          :lazy-src="$api_url+item.Url"
                        
                          :alt="item.Name"
                          :title="item.Name"> </v-img>
                  
                </v-list-item-card>
                <v-list-item-card class="text-subtitle-1 text-wrap"  style="width:100%;text-indent:20px;"  @click="ShowEnlarge2(item)">
                  <span>{{item.Name}}<v-icon style="float:right">mdi-television-guide</v-icon></span>
                
                  <span class="text-caption" v-html="formatData(item.CreatedDate)" style="float:right"></span>
                </v-list-item-card>
                 
              </v-list-item>
              <v-divider ></v-divider>
              </v-list>
            </div>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-end mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify. breakpoint.smAndDown ?  cols='6' : cols='12'">
              <div class="mx-auto text-center justify-center"    :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;' : 'width:350px;'" align="center">
                <v-card-subtitle class="text-center" align="center" style="width:100%;">
                  <div align="center">
                    <v-img v-if="pic_source2==''" :src="$api_url+EventData[0].Url" @click="ShowPic($api_url,EventData[0])" height="300" contain :width="!$vuetify.breakpoint.smAndDown ? 600 : 250" align="center"/>
                    <v-img v-if="pic_source2!=''" :src="$api_url+pic_source2"  max-height="300" contain max-width="600" align="center"/>
                  </div>
                 
                </v-card-subtitle>
                <v-card-subtitle class="text-h5 text-center" v-if="pic_source2==''"  >
                '' {{this.EventData[0].Name}} ''
                </v-card-subtitle>
                <v-card-subtitle style="text-indent:10px"  v-if="pic_source2==''" > 
                  &nbsp;&nbsp;&nbsp;&nbsp;{{this.EventData[0].Content}}
                </v-card-subtitle>
                <v-card-subtitle class="text-h5 text-center" v-if="pic_source2!=''">
                  '' {{SelectedName2}} ''
                  </v-card-subtitle>
                  <v-card-subtitle style="text-indent:10px" v-if="pic_source2!=''"> 
                    &nbsp;&nbsp;&nbsp;&nbsp;{{SelectedContent2}}
                  </v-card-subtitle>
              </div>
        </v-col>
      </v-row>
       <!-- <v-col :class="!$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center mx-auto' : 'd-flex pt-1 justify-center mx-auto'" v-if="!$vuetify.breakpoint.smAndDown ?  cols='4' : cols='12'">
          
          <div class="mx-auto text-center" @click="ClosePic"   :style="!$vuetify.breakpoint.smAndDown ? 'width:99%;background:#f3f3f3' : 'width:350px;background:#f3f3f3'" >
            <v-card-subtitle class="text-center" >
              <v-img v-if="pic_source==''" :src="$api_url+TableData[0].Url" @click="ShowPic($api_url,TableData[0])" height="300" contain :width="!$vuetify.breakpoint.smAndDown ? 600 : 250"/>
              <v-img v-if="pic_source!=''" :src="pic_source"  max-height="300" contain max-width="600"/>
            </v-card-subtitle>
            
           
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source==''"  >
            '' {{this.TableData[0].Name}} ''
            </v-card-subtitle>
            <v-card-subtitle style="text-indent:10px"  v-if="pic_source==''" > 
              &nbsp;&nbsp;&nbsp;&nbsp;{{this.TableData[0].Content}}
            </v-card-subtitle>
            <v-card-subtitle class="text-h5 text-center" v-if="pic_source!=''">
              '' {{title}} ''
              </v-card-subtitle>
              <v-card-subtitle style="text-indent:10px" v-if="pic_source!=''"> 
                &nbsp;&nbsp;&nbsp;&nbsp;{{content}}
              </v-card-subtitle>
          </div>
        </v-col> -->
    
    
  <!-- <v-row  dense> -->
      <!--<v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
    >   
    <v-card>
      <v-card-title
            class="text-uppercase text-sm-caption text-md-h6
            font-weight-bold text-center blue--text"
        >
          
        NEWS
     
      </v-card-title> 
    </v-card>

    <v-card 
     class="overflow-auto"
     :max-height="$vuetify.breakpoint.lgAndUp ? 500 : 150"
    >
      <v-list three-line >
        <template v-for="(item, index) in this.News">
        
          <v-divider
            :key="index"
            :inset="true"
          ></v-divider>

          <v-list-item
            :key="item.ID"
          >

        
        <v-list-item-avatar
        
          tile
          size="80"
          color="grey"
        >
       
        <v-img
            @mouseover="item.Show = true"
            @mouseleave="item.Show = false"
            :height="$vuetify.breakpoint.lgAndUp ? 80 : 150"
            :width="$vuetify.breakpoint.lgAndUp ? 80 : 350"
            :aspect-ratio="16/9"
            :src="$api_url+item.Url"
            :lazy-src="$api_url+item.Url"
          
            :alt="item.Name"
            :title="item.Name"
          >
          </v-img>
         
         
          
      
        </v-list-item-avatar>
        
            <v-list-item-content>
              <a  @click="$router.push('/viewcontent/'+item.ID+'/news')">
              <v-list-item-title class="text-h7 mb-1" v-html="item.Name"></v-list-item-title>
              </a>
              <v-list-item-subtitle class="text-justify mb-3" v-html="item.Content"></v-list-item-subtitle>
              <v-list-item-title  class="text-overline mb-4 d-flex flex-row-reverse" v-html="formatData(item.CreatedDate)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>   
    <v-footer padless >
          <v-btn  text  color="teal accent-4"        
          to="/news"
        >
        
          More News
        </v-btn>
        <v-spacer></v-spacer>
     </v-footer>
    </v-col> -->
    <!-- <v-col
    :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
    >   
    <v-card>
     <v-card-title
          class="text-uppercase 
          text-sm-caption text-md-h6
          font-weight-bold text-center blue--text"
      
      >
     
      EVENTS
     
    </v-card-title> 
    </v-card>

     <v-card 
     class="overflow-auto"
     :max-height="$vuetify.breakpoint.lgAndUp ? 500 : 150"
     >
     
      <v-list three-line >
        <template v-for="(item, index) in this.EventData">
        
          <v-divider
            :key="index"
            :inset="true"
          >
          </v-divider>

          <v-list-item
            :key="item.ID"
          >
        <v-list-item-content>
              
              <enlargeable-image :src="$api_url+item.Url" :src_large="$api_url+item.Url" />
            
              <a @click="$router.push('/viewcontent/'+item.ID+'/events')">
              <v-list-item-title class="text-h7 mb-1" v-html="item.Name"></v-list-item-title>
              </a>
              <v-list-item-subtitle class="text-justify mb-3" v-html="item.Content"></v-list-item-subtitle>
              <v-list-item-title  class="text-overline mb-4 d-flex flex-row-reverse" v-html="formatData(item.CreatedDate)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      
      </v-card>   
      <v-footer padless >
          <v-btn text  color="teal accent-4"  to="/event"
        >
          More Events
        </v-btn>
        <v-spacer></v-spacer>
       </v-footer>
    </v-col> -->
   
    <!-- <v-col
    :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
    >    -->
    <!-- <v-card>
     <v-card-title
          class="text-uppercase 
          text-md-h6 text-sm-body-2
          font-weight-bold text-center blue--text"
          
    >
      BIDDINGS
    </v-card-title> 
    </v-card>

   
    <v-card 
      class=""
      :max-height="$vuetify.breakpoint.mdAndUp ? 500 : 150"
      :max-width="$vuetify.breakpoint.mdAndUp ? 600 : 350"
    >
      <Biddings/>

    </v-card>    
     <v-footer padless >
          <v-btn text  color="teal accent-4"  to="/biddings"
        >
          More Biddings
        </v-btn>
        <v-spacer></v-spacer>
       </v-footer> -->
    <!-- </v-col> -->

  <!-- </v-row>   -->
  </v-container>
  <!-- <v-dialog persistent v-model="EnlargeImage" style="width:900px;max-width: 80vw;z-index:99999;background:none" align="center">
    <div  style="width:900px;max-width: 80vw;background:white;text-align:center;border-raduis:10px" class="mx-auto justify-center " aling="center">
      <div class="text-uppercase gray lighten-1
      text-h6 pa-1
      font-weight-bold text-center white--text  justify-center"
      style="background:#5167c2" align="center">
        <b>" {{SelectedName}} "</b>
        <v-icon  clickable @click="ClosePopup" style="float:right" color="white" size="lg">mdi-close</v-icon>
      </div>
      <enlargeable-image :src="$api_url+SelectedUrlImage" :src_large="$api_url+SelectedUrlImage" class="pt-1"/>
      
      <div class="text-subtitle justify-center text-wrap" style="margin;auto;width:100%;text-indent:30px;text-align:center" align="center">
       
        <span>{{SelectedContent}}</span>
      </div>
    </div>
  </v-dialog> -->
    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
   
  </div>
</template>

<script>

//import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper' 


// import Biddings from '@/views/Biddings' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
// import style (>= Swiper 6.x)
//import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import TitleComponent from '@/components/TitleComponent'
// import EnlargeableImage from '@diracleo/vue-enlargeable-image';


export default {
  name : 'MunicipalActivities',
  components: {
    //TitleComponent,
    Footer,
    //Swiper,
    //SwiperSlide,
    // EnlargeableImage,
    TitleComponent,
    // Biddings 
  },

  /*directives: {
    swiper: directive
  },
*/

  data () {
    return {
      pic_source: "",
      SelectedContent: "",
      SelectedName: "",
      SelectedUrlImage: "",
      pic_source2: "",
      SelectedContent2: "",
      SelectedName2: "",
      SelectedUrlImage2: "",
      EnlargeImage: false,
      show: false,
      TableData:[],
      EventData:[],
      AnnouncementData:[],
      NewsData:[],
      CovidUpdates:[],
      VaccineUpdates:[],
      News:[],
      swiperOptions: {
          slidesPerView: 'auto',
          spaceBetween: 5,
          direction:'vertical',
          mousewheel: true,
          freeMode: true,
          pagination: {
          el: '.swiper-pagination',
          clickable: true
        },  
        scrollbar: {
          el: '.swiper-scrollbar'
        },
        // Some Swiper option/callback...
      },
      swiperOptionflip: {
        effect: 'flip',
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94
        },
      }
    }
    },

    mounted(){
      //alert(this.EventItems);
      
      this.getAllRecords(`/getAllRecords/events/where ID > 0 /ID desc LIMIT 0,8`,'events');
      this.getAllRecords(`/getAllRecords/announcements/where ID > 0 /ID desc`,'announcements');
      this.getAllRecords(`/getAllRecords/news/where ID > 0 /ID desc  LIMIT 0,8`,'news');
      //alert(this.EventData);
     this.swiper.slideTo(3, 1000, false) 

    },  

    updated(){

     
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{
      ShowEnlarge(array){
        
        this.SelectedUrlImage = array.Url;
        this.SelectedName = array.Name;
        this.SelectedContent = array.Content;
        this.pic_source = array.Url;
        // this.EnlargeImage = true;
      },
      ShowEnlarge2(array){
        
        this.SelectedUrlImage2 = array.Url;
        this.SelectedName2 = array.Name;
        this.SelectedContent2 = array.Content;
        this.pic_source2 = array.Url;
        // this.EnlargeImage = true;
      },
      // ClosePopup(){
      //   this.EnlargeImage = false;
      // },
      toggleshow(EventItem){
        EventItem.Show=!EventItem.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },

      formatDate(value) {
        //alert(value);
        if ( (value !== '0000-00-00') && (value) ) {
            
            const date = new Date(value)
            return date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric'})  
            //if you want, you can change locale date string
        }
        else{
            if (value==null){
                value='0000-00-00';
            }
            return value
        }
      },

      getAllRecords:function(query,table){
      this.$axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(table=='events'){
                  this.EventData=response.data
              }
              else if(table=='announcements'){
                  this.AnnouncementData=response.data
                 
              }
              else if(table=='covidupdates'){
                  this.CovidUpdates=response.data
                 
              }
              else if(table=='vaccineupdates'){
                  this.VaccineUpdates=response.data
                  
              }
              else if(table=='news'){
                  this.News=response.data
                  
              }
              
              //return response.data
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss" scoped>
  @import 'swiper/swiper.scss';


</style>