<template>
  <div class="white--text">Philippine Standard Time: {{ date }} {{ time }}</div>
</template>

<script>
export default {
  name : 'Clock',
  data() {
    return {
      date : '',
      time : '',
      week : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      month : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  },
  methods: {
    updateTime : function() {
        var cd = new Date();
        this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
        this.date = this.week[cd.getDay()]+',' + ' ' + this.month[cd.getMonth()] + ', ' + this.zeroPadding(cd.getDate(), 2) + ' ' + this.zeroPadding(cd.getFullYear(), 4);
    },

    zeroPadding : function(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
    }
  },
  mounted() {
    setInterval(this.updateTime, 1000);
    this.updateTime();
  }
}
</script>

<style lang="scss">
</style>