<template>
<!-- hide-delimiters cycle :height="$vuetify.breakpoint.mdAndDown ? '200' : '500'" -->
 

  <div style="margin-top:-15px">
    
    <v-carousel :cycle="cycle" interval="6000" 
    
    :height="$vuetify.breakpoint.mdAndUp ? 800 : 400"
    
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="$api_url+item.Url"
        style="width:100%"
      >

      <v-container v-if="item.IsVideo == true" fill-height fluid>
        <v-row align="center"
            justify="center">
            <v-col class="text-center">
              <v-btn
                class="mx-2"
                fab
                dark
                large
                color="primary"
                @click.stop="open"
              >
                <v-icon dark>
                  mdi-play
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
      </v-container>
      </v-carousel-item>
    </v-carousel>

    <v-dialog
      v-model="dialog"
      persistent
     style="width:800px;min-width:90vw;z-index:99999"
    >
    
      <v-card>
        <v-row>
        <v-col
        cols="7">
        <v-card-title :class="!$vuetify.breakpoint.smAndDown ? 'headline' : 'text-caption'">
          San Jose Batangas
        </v-card-title>
        </v-col>
        <v-col cols="5">
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
            
          >
            Close
          </v-btn>
        </v-card-actions>
        </v-col>
        </v-row>
        <v-card-text>
          <VideoHome :test="play"/>
        </v-card-text>

        

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios' 
import VideoHome from '@/components/VideoHome';
export default {
  name : 'carousels',
  components : {
    VideoHome
  },
  data() {
    return {
      items:[],  
      itemsv1: [
          {
            src : require('../assets/slider/slider-image-1.png'),
            isVideo : true,
            desc: 'lorem'
          },
          {
            src: require('../assets/slider/slider-image-2.png'),
            isVideo : false,
            desc: 'lorem'
          },
          {
            src: require('../assets/slider/slider-image-3.png'),
            isVideo : false,
            desc: 'lorem'
          },
          {
            src: require('../assets/slider/slider-image-4.png'),
            isVideo : false,
            desc: 'lorem'
          },
          {
            src: require('../assets/slider/slider-image-5.png'),
            isVideo : false,
            desc: 'lorem'
          }
      ],
      dialog : false,
      cycle : false,
      play : true
    }
  },
  mounted(){
    this.getAllRecords();
  },  
  methods : {
    close : function() {
      this.dialog = false;
      this.play = false;
      this.cycle = true;
    },
    open : function() {
      this.dialog = true;
      this.cycle = false;
      this.play = true
    },

    getAllRecords:function(){
      //alert(this.BranchCode);       
      axios.get( this.$api_url+`/getAllRecords/carousels/where ID > 0/ ID asc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.items = response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },  

  }
}
</script>

<style lang="">
  
</style>