import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#324FA6',
        secondary: '#04B2D9',
        accent: '#ffd760',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        secaccent: '#ffecb3',
        thiaccent: '#fff3ce',
        white : '#fff'
      },
    },
  }
});
