import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vPlayBack from './plugins/vPlayBack'
import moment from 'moment'
import axios from 'axios' 
import 'vuetify/dist/vuetify.min.css' 
//import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
//import 'swiper/swiper.scss';

//import * as VueGoogleMaps from 'vue2-google-maps'

Vue.prototype.moment = moment
Vue.prototype.$axios = axios
Vue.prototype.vuetify = vuetify
Vue.prototype.$api_url = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000'
//Vue.prototype.$api_urlfiles = process.env.NODE_ENV === 'production' ? '' : '.././admin'
Vue.config.productionTip = false

/*
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCr6qZO8hbCEWmnFydjcwiQGElaSOIMkyI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
}),
*/
new Vue({
  router,
  store,
  vuetify,
  moment,
  vPlayBack,
  watch: {
    $route(to) {
        document.title = 
          to.meta.title || "San Jose Batangas Official Web Page";
    },
},
  render: h => h(App)
}).$mount('#app')
