<template>
  <div style="background:#f2f2f2;position:fixed;z-index:9999;width:100%">
    <TopHeader v-if="!$vuetify.breakpoint.smAndDown"/>
    <TopLogoHeader :menu="menu" v-if="!$vuetify.breakpoint.smAndDown"/>
    
    <v-navigation-drawer temporary v-model="sideNav" app >
     
      <v-card
        class="mx-auto"
        width="300"
      > 
      <v-list>
        <div v-for="(item, i) in menu" :key="i" >
          
          <v-list-group
            :value="true"
            :prepend-icon="$vuetify.breakpoint.mdAndUp ? '' : item.IconName"
            
          >  
          <!--            -->
          <template v-slot:activator>
            <v-list-item :to="item.link">
                <v-list-item-content>
                   <v-list-item-title class="title body-2 primary--text pa-1" >{{item.name}}</v-list-item-title>
                 </v-list-item-content>
            </v-list-item>
           </template>
         <v-divider></v-divider>

          <div v-if="item.children">
              <v-list-group 
                :value="true"
                sub-group
                no-action
                v-for="(itemchild, j) in item.children" 
                :key="j"
               >
                <template v-slot:activator>
                  <v-list-item :to="itemchild.link">
                     <!-- <v-list-item-content> -->
                      <v-list-item-title  :class="$vuetify.breakpoint.mdAndUp ? 'text-subtitle-2' : 'text-caption' ">{{itemchild.name}}</v-list-item-title>
                    <!-- </v-list-item-content> -->
                    <!-- <v-list-item-icon>
                      <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon> -->
                  </v-list-item>  
                </template>
              </v-list-group>
          </div>
          </v-list-group> 
        </div> 
        </v-list>
      </v-card>
    </v-navigation-drawer>
    
    <v-toolbar dark flat class="primary"  v-if="$vuetify.breakpoint.smAndDown" >
      
      <v-app-bar-nav-icon @click.native.stop="sideNav = !sideNav" class="d-flex d-lg-none"></v-app-bar-nav-icon>

      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
      
       <v-toolbar-title class="pa-0" @click="$router.push('/')">
        <!-- :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-3'" -->
        <img :src="require('../assets/san-jose-logo.png')" class="mt-2" :class="$vuetify.breakpoint.smAndDown ? 'mr-5' : 'mr-3'"  height="58" /> 
      </v-toolbar-title>

      <v-spacer></v-spacer>
      
    </v-toolbar>
  </div>
 
</template>

<script>
import TopHeader from '@/components/TopHeader'
import TopLogoHeader from '@/components/TopLogoHeader'
import departmentData from "@/json/department.json";

export default {
  link : 'Navbar',
  components: {
    TopHeader,
    TopLogoHeader
  },
  data() {
    return {
        sideNav : false,
        departments:departmentData,
        menuDepartment:[],
        menuItems : [
          { link : '/about', name : 'About'},
          { link : '/services', name : 'Services'},
          { link : '/projects', name : 'Projects'},
          { link : '/sbmembers', name : 'SB Members'},
          { link : '/tourism', name : 'Tourism'},
          { link : '/news', name : 'News'},
          { link : '/fulldisclosure', name : 'Full Disclosure'},
          //{ link : '/gallery', name : 'Gallery'},
          { link : '/calendar', name : 'Calendar'},
          { link : '/downloadables', name : 'Downloadables'},
          { link : '/maps', name : 'Maps'}
        ],
        
        menu : [
          {
            name : 'The Municipality',
            link : '/about',
            IconName: "mdi-home-outline"
            
          },
           {
            name : 'Services',
            link : '/services',
            IconName: "mdi-offer"
          },
          {
            name : 'Projects',
            link : '',
            IconName: "mdi-animation-outline",
            children : [
              {
                name : 'Completed Projects',
                link : '/completedprojects'
              },
              {
                name : 'Ongoing Projects',
                link : '/ongoingprojects'
              },
              {
                name : 'Bidding',
                link : '/Biddings'
              },
            ]
          },
          {
            name : 'SB Members',
            link : '/sbmembers',
            IconName: "mdi-account-supervisor-outline"
          },
         
          {
            name : 'Tourism',
            link : '/tourism',
            IconName: "mdi-island",
          },
          
          {
            name : 'News',
            link : '/news',
            IconName: "mdi-lightbulb-on-outline",
            children : [
              {
                name : 'Covid Updates',
                link : '/covidupdates'
              },
              
            ]
          },
          /*{
            name : 'Gallery',
            link : '/gallery'
          },*/
          {
            name : 'Full Disclosure',
            link : '/fulldisclosure',
            IconName: "mdi-ballot-outline"
          },
          {
            name : 'Calendar',
            link : '/calendar',
            IconName: "mdi-calendar"
          },
          {
            name : 'Downloadables',
            link : '/downloadables',
            IconName: "mdi-cloud-arrow-down-outline"
          },
          {
            name : 'Maps',
            link : '/maps',
            IconName: "mdi-billboard"
          },
        ]
    }
  },
  mounted(){
   
    //this.getDepartmentmenu();
    //position of department menu
    //this.menu.splice(3,0,{name:"Department",link:"",children:this.menuDepartment});
  },

  methods:{
    getDepartmentmenu(){
      for(var i=0;i<this.departments.length;i++){
        //alert(this.departments[i]['menu_name']);
        this.menuDepartment.push({name:this.departments[i]['menuName'],link:"/departments/"+this.departments[i]['link']});
      }
      
       for(var j=0;j<this.menuDepartment.length;j++){
        //alert(this.menuDepartment[j]['name']);
        //this.menuDepartment.push({name:this.departments[i]['menu_name'],link:"/departments"});
      }

        

    },

  }
}
 
</script>

<style lang="scss" scoped>
  .time-block {
    padding: .2em;
  }
</style>