<template>
  <!--<div class="primary">-->
    <v-container fluid>
     <v-row>
         
        <!-- <div class=""> -->
           <v-img
            @click="$router.push('/')"
              class="d-flex flex-row justify-start"
              style="position:fixed;z-index:9999;margin-top:2px;margin-left:5px;cursor:pointer"
              :src="require('../assets/logo.png')"
              tile>
            </v-img>
            <div style="color:yellow;position:fixed;margin-top:60px;margin-left:115px" class="text-caption">" Bayan ay aasenso,kapag ang <b>TAO</b> ay <b>DISIPLINADO "</b></div>
             <!--../assets/san-jose-logo.png-->
          <!-- </div>  -->
          <div style="margin-left:100px" class="d-flex justify-center align-baseline">
            <div  v-for="item in menu" :key="item.name" class="d-flex justify-center align-baseline" style="gap: 1rem;marign-top:3px;">
              <v-menu open-on-hover offset-y rounded="0" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                    x-large
                    text 
                    tile
                    :to="item.link"
                  
                  >
                    {{ item.name }}
                  </v-btn>
                </template>
  
                <v-list v-if="item.children" class="blue lighten-5">
                    <v-list-item class="body-2 primary--text" link  
                      v-for="ch in item.children" 
                      :key="ch.name" 
                      v-text="ch.name" 
                      :to="ch.link"
                     
                    >
                    </v-list-item>
                             
                </v-list>
              </v-menu>
            </div>
          </div>
          
        
      </v-row>
    </v-container>
  <!--</div>-->
</template>

<script>
export default {
  name : 'TopLogoHeader',
  props : ['menu']
}
</script>

<style lang="scss" scoped>
  .test {
    color : red;
  }
</style>